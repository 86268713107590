import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ArmyBuilderConfig, PurchasingService } from 'army-builder-shared';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { Capacitor } from '@capacitor/core';

import { SubscriptionService } from './subscription.service';
import { Router } from '@angular/router';
import { ToastService } from 'army-builder-shared/src/lib/global/toast/toast';

@Component({
    selector: 'wl-paywall',
    template: `
        <abs-modal-layout
            class="home"
            (close)="closeModal()"
            [hideWrapper]="true"
            [showClose]="false"
            *ngLet="{ products: products$ | async, freeTrial: freeTrial$ | async, userRoles: userRoles$ | async } as props"
        >
            <div class="transparent products" *ngIf="props.products?.length > 0">
                <div class="product" *ngFor="let product of props.products; let i = index" lines="full" [class.alt]="i % 2 === 0">
                    <div class="imgWrap">
                        <img src="/assets/images/paywall/{{ product.key }}.jpg" alt="" />
                    </div>
                    <h2>
                        {{ product.name | language | async }}
                    </h2>
                    <div class="price">{{ product.displayPrice }}</div>
                    <div class="productDescription" [innerHTML]="product.description | language | async"></div>
                    <ion-card *ngIf="props.freeTrial && product.supportsFreeTrial">
                        <ion-card-content> You are currently on a free trial </ion-card-content>
                    </ion-card>
                    <div class="buttons">
                        <ng-container *ngIf="!props.userRoles.includes(product.associatedRole) || props.freeTrial; else owned">
                            <ion-button (click)="purchase(product.key)" *ngIf="platform !== 'web'">
                                {{ 'GLOBAL.SUBSCRIPTION.SUBSCRIBE' | translate }} - {{ product.displayPrice }}
                            </ion-button>

                            <wl-subscribe-button-web *ngIf="platform === 'web'" [product]="product"></wl-subscribe-button-web>
                        </ng-container>

                        <ng-template #owned>
                            <ion-button [disabled]="true" *ngIf="(purchases$ | async).indexOf(product.key) > -1">{{
                                'GLOBAL.SUBSCRIPTION.OWNED' | translate
                            }}</ion-button>
                        </ng-template>
                    </div>
                </div>
            </div>
            <ion-card *ngIf="!loading && !props.products?.length">
                <ion-card-header>
                    <ion-card-title>{{ 'GLOBAL.SUBSCRIPTION.MISSING_PRODUCTS.TITLE' | translate }}</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                    <p>{{ 'GLOBAL.SUBSCRIPTION.MISSING_PRODUCTS.DESCRIPTION' | translate }}</p>
                    <ion-button (click)="loadProducts()">{{ 'GLOBAL.SUBSCRIPTION.MISSING_PRODUCTS.RETRY' | translate }}</ion-button>
                </ion-card-content>
            </ion-card>

            <ion-card *ngIf="loading">
                <ion-card-content>
                    <ion-spinner></ion-spinner>
                </ion-card-content>
            </ion-card>
        </abs-modal-layout>
    `,
    styles: [
        `
            :host {
                padding: 20px;
            }

            .products {
                display: flex;
                margin: 0 auto;
                overflow: auto;
                height: 100%;
            }

            @media (max-width: 600px) {
                .products {
                    display: block;
                }
            }

            .product {
                flex: 1 1 100%;
                background: #222;
                color: white;
                display: flex;
                flex-direction: column;
                margin: 5px;
            }

            .price {
                text-align: center;
                padding: 20px 20px 0;
                font-size: 1.2em;
                font-weight: bold;
            }

            .productDescription {
                padding: 20px;
                flex: 1 1 auto;
            }
            .productDescription ::ng-deep p {
                margin: 0 0 20px;
                text-align: center;
            }

            .productDescription ::ng-deep ul {
                padding-left: 20px;
            }

            .product h2 {
                text-align: center;
                margin: 0;
                width: 100%;
                padding: 10px;
                background: #555;
                font-size: 1.1em;
            }
            .imgWrap {
                height: 150px;
                overflow: hidden;
            }
            .buttons {
                justify-content: center;
                padding-bottom: 20px;
            }
        `
    ]
})
export class PaywallComponent {
    freeTrial$ = this.subscriptionService.freeTrial$;
    userRoles$ = this.subscriptionService.userRoles$;
    platform = Capacitor.getPlatform();
    public purchases$ = this.purchasingSvc.purchases$;
    public products$ = combineLatest([this.purchasingSvc.products$, this.userRoles$, this.freeTrial$]).pipe(
        map(([products, userRoles, freeTrial]) =>
            products.filter((p) => {
                const isAvailableOnThisPlatform = p.availablePlatforms?.includes(this.platform as any);
                if (!isAvailableOnThisPlatform) {
                    return false;
                }

                const hasAccessToProduct = userRoles.includes(p.associatedRole);
                if (hasAccessToProduct && !freeTrial) {
                    return false;
                }

                return true;
            })
        )
    );

    public loading = false;

    public debug = false;

    constructor(
        public purchasingSvc: PurchasingService,
        public config: ArmyBuilderConfig,
        private subscriptionService: SubscriptionService,
        private modalController: ModalController,
        private router: Router,
        private toast: ToastService
    ) {}

    purchase(productId: string) {
        this.purchasingSvc.purchase(productId).then((success) => {
            if (success) {
                this.router.navigateByUrl('/subscription/handle-purchase');
            } else {
                this.toast.showToast(
                    'Could not complete purchase',
                    'Something went wrong when trying to complete your purchase.  Please contact warlord@maloric.com if you need assistance.',
                    { color: 'danger' }
                );
            }
        });
    }

    restorePurchases() {
        this.purchasingSvc.restorePurchases();
    }

    closeModal() {
        this.modalController.dismiss();
    }

    async loadProducts() {
        this.loading = true;
        await this.purchasingSvc.loadProducts();
        this.loading = false;
    }
}
