import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import {
    ENTITY_LIBRARY_ACTIONS,
    EntityLibraryLoadStatus,
    EntityLibraryState,
    GameEntityLibraryState,
    GameLibraryState,
    initialEntityLibraryGameState
} from '../army-builder-entity-store';
import { updateLibraryStatusReducer } from './updateLibraryStatusReducer';

export const dataReducer =
    <T>(gameId: string, dataType: string, status: EntityLibraryLoadStatus) =>
    (state: EntityLibraryState, { data }: { data: T[] }): EntityLibraryState => ({
        ...state,
        data: {
            ...state.data,
            [gameId]: {
                ...state.data[gameId],
                [dataType]: {
                    status,
                    data
                }
            }
        }
    });

export const entityDataReducer = createReducer(
    initialEntityLibraryGameState,
    on(
        ENTITY_LIBRARY_ACTIONS.INIT_GAME_LIBRARY,
        (state: EntityLibraryState, { gameId, initialState }: { gameId: string; initialState: GameLibraryState }) => {
            return {
                ...state,
                data: { ...state.data, [gameId]: initialState }
            };
        }
    ),
    on(ENTITY_LIBRARY_ACTIONS.UPDATE_LIBRARY_STATUS, updateLibraryStatusReducer),
    on(ENTITY_LIBRARY_ACTIONS.LOAD_ENTITY_DATA_COMPLETE, (state, { gameId, dataType, data }) => {
        return dataReducer(gameId, dataType, 'LOADED')(state, { data });
    }),
    on(ENTITY_LIBRARY_ACTIONS.SHOW_ENTITY_PRELOAD_MODAL, (state, { gameId }) => {
        return {
            ...state,
            showPreloadModal: gameId
        };
    }),
    on(ENTITY_LIBRARY_ACTIONS.HIDE_ENTITY_PRELOAD_MODAL, (state) => {
        return {
            ...state,
            showPreloadModal: null
        };
    })
);

export const entityDataSelector = createFeatureSelector('entity');
