import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs';

import { SettingsService } from '../service/settings.service';
import { ArmyBuilderConfig, SettingsFieldConfig, SettingsGameConfig } from '../../config';

@Component({
    selector: 'abs-settings',
    template: `
        <ng-container *ngLet="{ settings: settings$ | async } as props">
            <abs-list [items]="fields" [template]="settingsTemplate" [showDelete]="false"></abs-list>
            <ng-template #settingsTemplate let-key="item[0]" let-field="item[1]">
                <div class="setting {{ key }}">
                    <h2>{{ 'GLOBAL.SETTINGS.' + (gameId | uppercase) + '.' + (key | uppercase) + '.TITLE' | translate }}</h2>
                    <p>{{ 'GLOBAL.SETTINGS.' + (gameId | uppercase) + '.' + (key | uppercase) + '.DESCRIPTION' | translate }}</p>

                    <ion-toggle
                        *ngIf="field.type === 'boolean'"
                        [checked]="!!props.settings?.[key] ?? field.defaultValue"
                        (ionChange)="toggleChanged(key, $event)"
                    ></ion-toggle>
                </div>
            </ng-template>
        </ng-container>
    `,
    styles: [
        `
            .setting {
                padding-bottom: 10px;
            }
        `
    ]
})
export class SettingsComponent implements OnInit {
    @Input({ required: true })
    gameId: string;

    settings$ = this.settingsService.settings$.pipe(map((settings) => settings[this.gameId] ?? {}));
    fields: [string, SettingsFieldConfig][];
    constructor(
        public settingsService: SettingsService,
        public config: ArmyBuilderConfig
    ) {
        console.log(this.config.settings);
    }

    ngOnInit() {
        this.fields = Object.entries(this.config.settings[this.gameId] || {});
    }

    toggleChanged(key: string, event: any) {
        this.settingsService.updateValue(this.gameId, key, event.detail.checked);
    }
}
