import { TranslateLoader } from '@ngx-translate/core';
import { catchError, forkJoin, map, Observable, shareReplay } from 'rxjs';
import { HttpClientWithInFlightCache } from '../httpClient';
import { Store } from '@ngrx/store';
import { Inject, inject, Injectable, Optional } from '@angular/core';
import { ArmyBuilderConfig } from '../config';
import { TRANSLATION_ACTIONS } from '../state';
import { GAME_ID, RestDataService } from 'army-builder-shared';

@Injectable({ providedIn: 'root' })
export class CustomLoader extends TranslateLoader {
    private http = inject(HttpClientWithInFlightCache);
    protected store = inject(Store);
    constructor(
        @Inject(GAME_ID) @Optional() public gameId: string,
        protected config: ArmyBuilderConfig,
        protected restDataService: RestDataService
    ) {
        super();
    }

    loadData(gameId: string, lang: string, fallbackToCache = true) {
        let apiBaseUrl = this.config.apiBaseUrl;
        const url = `${apiBaseUrl}/translation/${gameId}/${lang}`;

        this.store.dispatch(TRANSLATION_ACTIONS.TRANSLATION_LOADING({ gameId, lang }));
        this.restDataService
            .get(url, {
                cacheKey: `translation_${gameId}_${lang}`,
                preferCache: false,
                fallbackToCache
            })
            .pipe(
                // defaultIfEmpty({}),
                map((x: any) => {
                    return x.value;
                }),
                catchError((err) => {
                    console.error(`Error loading translations from ${url}:`, gameId, err);
                    setTimeout(() => {
                        this.store.dispatch(TRANSLATION_ACTIONS.TRANSLATION_ERROR({ gameId, lang }));
                    }, 1000);
                    throw err;
                }),
                shareReplay(1)
            )
            .subscribe((data) => {
                console.log(`Translations loaded (${gameId}:${lang})`, data);
                console.log('Translations: A new value was pushed to the store');
                this.store.dispatch(TRANSLATION_ACTIONS.TRANSLATION_LOADED({ gameId, lang, data }));
            });
    }

    public getTranslation(lang: String): Observable<any> {
        let get = (file: string) => this.http.get('assets/i18n/' + lang + '/' + file + '.json');
        return forkJoin({
            GLOBAL: get('global'),
            FORCES: get('forces')
        });
    }
}
