import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Faction, UnitOption, UnitTemplate } from '../forces/models';
import { HttpRequestConfig, RestDataService } from './restDataService';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export abstract class DataLibrary {
    abstract factions$: Observable<Faction[]>;
    abstract unitTemplates$: Observable<UnitTemplate[]>;
    abstract options$: Observable<UnitOption[]>;

    ready$ = new BehaviorSubject<boolean>(false);
    abstract init(opts: HttpRequestConfig): void;

    constructor(
        protected restDataService: RestDataService,
        protected store: Store,
        protected route: ActivatedRoute
    ) {}
}
