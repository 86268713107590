import { Component } from '@angular/core';
import { LANGUAGE, SettingsService, TRANSLATION_ACTIONS } from 'army-builder-shared';
import { GAME_IDS } from '../game-id.token';
import { Store } from '@ngrx/store';
import { WarlordCustomLoader } from '../global/i18n/warlord-custom-loader';

@Component({
    selector: 'wl-settings',
    template: `
        <abs-page-layout
            title="Settings"
            *ngLet="{
                loginState: settingsService.login$ | async
            } as props"
        >
            <div class="content">
                <ion-card class="subscription">
                    <ion-card-header>
                        <ion-card-title>{{ 'GLOBAL.SETTINGS.ACCOUNT.MANAGE_SUBSCRIPTION' | translate }}</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        <wl-manage-subscription></wl-manage-subscription>
                    </ion-card-content>
                </ion-card>

                <ion-card class="devices">
                    <ion-card-header>
                        <ion-card-title>{{ 'GLOBAL.SETTINGS.DEVICES.TITLE' | translate }}</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        <abs-devices></abs-devices>
                    </ion-card-content>
                </ion-card>

                <abs-settings [gameId]="'global'"></abs-settings>

                <div class="buttons">
                    <ion-button (click)="refreshLanguageData()">Refresh language data</ion-button>
                </div>

                <ion-card class="account">
                    <ion-card-header>
                        <ion-card-title>{{ 'GLOBAL.SETTINGS.ACCOUNT.MANAGE_ACCOUNT' | translate }}</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        <wl-user></wl-user>
                    </ion-card-content>
                </ion-card>

                <abs-debug></abs-debug>
            </div>
        </abs-page-layout>
    `,
    styles: [
        `
            .account .content {
                margin-bottom: 0;
            }
        `
    ]
})
export class SettingsPage {
    constructor(
        public settingsService: SettingsService,
        private store: Store,
        private warlordCustomLoader: WarlordCustomLoader
    ) {}

    refreshLanguageData() {
        const gameIds = ['global', ...Object.values(GAME_IDS)];

        const lang = LANGUAGE;

        this.store.dispatch(TRANSLATION_ACTIONS.SHOW_TRANSLATION_PRELOAD_MODAL());
        for (let gameId of gameIds) {
            this.store.dispatch(TRANSLATION_ACTIONS.TRANSLATION_LOADING({ gameId, lang }));
            this.warlordCustomLoader.loadData(gameId, LANGUAGE, false);
        }
    }
}
