import { TranslateLoader } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, defaultIfEmpty, filter, map, shareReplay, tap } from 'rxjs/operators';
import { TRANSLATION_ACTIONS, RestDataService, GAME_ID, SelectTranslations, CustomLoader } from 'army-builder-shared';

import { environment } from '../../../environments/environment';
import { Inject, inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class WarlordCustomLoader extends CustomLoader {
    protected translations: any = {};

    public getTranslation(lang: string): Observable<any> {
        console.log('Translations: getTranslation was called for ' + lang);
        const gameId = this.gameId;
        // if (!this.translations[lang]) {
        this.translations[lang] = this.store.select(SelectTranslations).pipe(
            map((translations) => {
                console.log('Translations: A new value was pushed from the store');
                const languageTranslations = translations?.[lang];
                const gameTranslations = languageTranslations?.[gameId]?.data;
                const globalTranslations = languageTranslations?.['global']?.data;

                if (!gameTranslations) {
                    return {};
                }

                const res = {
                    ...gameTranslations,
                    GLOBAL: {
                        ...globalTranslations,
                        ...gameTranslations.GLOBAL
                    }
                };

                return res;
            }),
            filter((res) => Object.keys(res)?.length > 0),
            tap((x) => console.log('Translations has a value at last', x)),
            catchError((err) => {
                setTimeout(() => {
                    this.store.dispatch(TRANSLATION_ACTIONS.TRANSLATION_ERROR({ gameId, lang }));
                }, 1000);
                return of({});
            })
        );
        // }

        return this.translations[lang];
    }
}
