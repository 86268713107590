import { Component, OnInit } from '@angular/core';
import { PageLayoutComponent as BasePageLayoutComponent } from 'army-builder-shared';
import { distinctUntilChanged, filter, take } from 'rxjs';

import { GAME_IDS } from '../../game-id.token';

@Component({
    selector: 'abs-page-layout',
    template: `
        <div [class]="theme">
            <ion-header>
                <ion-toolbar>
                    <ion-buttons slot="start">
                        <ion-back-button defaultHref=".." *ngIf="displayBackLink" (click)="navigateBack($event)"></ion-back-button>
                    </ion-buttons>
                    <ion-title>
                        <div class="title" (click)="titleClicked.emit()">{{ title }}</div>
                        <div class="subtitle" *ngIf="subTitle">{{ subTitle }}</div>
                    </ion-title>
                    <ion-buttons slot="end">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                </ion-toolbar>
            </ion-header>
            <ion-content #content fullscreen="true">
                <div class="wrap">
                    <ng-content></ng-content>
                </div>
            </ion-content>
        </div>
    `,
    styles: [
        `
            :host {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
            }

            :host > div {
                height: 100%;
            }

            .wrap {
                max-width: var(--wrapWidth, 600px);
                margin: 0 auto;
                padding-bottom: calc(100px + var(--ion-safe-area-bottom) + var(--offset-top, 0));
            }
        `
    ]
})
export class PageLayoutComponent extends BasePageLayoutComponent implements OnInit {
    ngOnInit(): void {
        this.routerService.routeData$.subscribe((d) => {
            let gameId = d.gameId;
            if (gameId === GAME_IDS.BOLT_ACTION_3E) {
                gameId = 'bolt-action';
            }
            this.themeService.selectTheme(gameId || 'home');
        });

        this.themeService.theme$
            .pipe(
                filter((t) => !!t),
                take(1)
            )
            .subscribe((t) => {
                this.theme = t;
            });
    }
}
