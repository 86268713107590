import { Component, ViewChild } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IonTextarea } from '@ionic/angular';
import { markFormTouched } from '../global/utils';
import { SupportService } from './support.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'abs-support',
    template: `
        <abs-modal-layout *ngLet="issueMetadata$ | async as issueMetadata">
            <div *ngIf="!complete" [formGroup]="supportForm">
                <p>
                    {{ 'GLOBAL.SUPPORT.DESCRIPTION' | translate }}
                </p>
                <p>
                    {{ 'GLOBAL.SUPPORT.LABELS.NAME' | translate }}: <strong>{{ issueMetadata[1]?.user?.name }}</strong>
                </p>
                <p>
                    {{ 'GLOBAL.SUPPORT.LABELS.EMAIL' | translate }}:
                    <strong>{{ issueMetadata[1]?.user?.email }}</strong>
                </p>
                <p>
                    {{ 'GLOBAL.SUPPORT.LABELS.PAGE' | translate }}: <strong>{{ issueMetadata[0]?.state?.url }}</strong>
                </p>
                <ion-textarea #description formControlName="description"></ion-textarea>
                <div class="buttons">
                    <ion-button [disabled]="supportForm.controls['description'].hasError('required')" (click)="submit()"
                        >{{ 'GLOBAL.BUTTONS.SUBMIT' | translate }} <ion-spinner *ngIf="submitted && !complete"></ion-spinner
                    ></ion-button>
                </div>
            </div>
            <ng-container *ngIf="complete">
                <p>{{ 'GLOBAL.SUPPORT.SUBMITTED_DESCRIPTION' | translate }}</p>
            </ng-container>
        </abs-modal-layout>
    `
})
export class SupportComponent {
    supportForm: UntypedFormGroup;
    submitted = false;
    complete = false;
    issueMetadata$ = this.supportService.issueMetadata$;

    @ViewChild('description')
    description: IonTextarea;

    constructor(
        fb: UntypedFormBuilder,
        private supportService: SupportService
    ) {
        this.supportForm = fb.group({
            description: [null, Validators.required]
        });
    }

    submit() {
        markFormTouched(this.supportForm);
        if (this.supportForm.valid) {
            let formValues = this.supportForm.getRawValue();
            this.submitted = true;
            this.supportService.submitReport(formValues.description).subscribe(() => {
                this.complete = true;
            });
        }
    }
}
