import { Injectable } from '@angular/core';
import { catchError, defaultIfEmpty, filter, map, shareReplay, switchMap, take, tap, timeout } from 'rxjs/operators';
import { combineLatest, firstValueFrom, from, Observable, of, throwError } from 'rxjs';

import { NativeStorageService } from '../data/native-storage.service';
import { SettingsService } from '../settings';
import { ArmyBuilderConfig } from '../config';
import { HttpClientRequestOptions, HttpClientWithInFlightCache } from '../httpClient';

const CACHE_TIMEOUT = 1000 * 60 * 60 * 24;

export interface HttpRequestConfig {
    cacheKey?: string;
    requiresLogin?: boolean;
    preferCache?: boolean;
    useCache?: boolean;
    fallbackToCache?: boolean;
}
@Injectable({ providedIn: 'root' })
export class RestDataService {
    serverCacheTime$: Observable<Date>;
    localCacheTime$: Observable<Date>;
    cacheUrl: string;
    cacheTimeKey = 'localCacheTime';
    constructor(
        private httpClient: HttpClientWithInFlightCache,
        private storage: NativeStorageService,
        private settingsService: SettingsService,
        private config: ArmyBuilderConfig
    ) {
        this.cacheUrl = this.config.apiBaseUrl + '/library/cacheTime';
        this.localCacheTime$ = from(this.storage.getItem(this.cacheTimeKey, null));
        this.serverCacheTime$ = this.httpClient.get(this.cacheUrl).pipe(
            shareReplay(1),
            catchError(() => of(new Date(0)))
        );
    }

    get(url: string, config: HttpRequestConfig) {
        const combinedConfig: HttpRequestConfig = {
            requiresLogin: false,
            preferCache: true,
            useCache: true,
            fallbackToCache: true,
            ...config
        };
        const opts: HttpClientRequestOptions = {
            headers: {}
        };

        let { cacheKey, requiresLogin, preferCache, useCache, fallbackToCache } = combinedConfig;

        if (requiresLogin) {
            opts.withCredentials = true;
            opts.requiresLogin = true;
        }

        return combineLatest([this.serverCacheTime$, this.localCacheTime$, this.settingsService.loggedIn$]).pipe(
            filter(([_serverCacheTime, _localCacheTime, loggedIn]) => {
                return !requiresLogin || loggedIn;
            }),
            switchMap(([serverCacheTime, localCacheTime, _l]) => {
                return from(
                    this.storage.getItem(cacheKey, undefined).then((cached) => {
                        const urlParams = new URLSearchParams(location.search);
                        if (urlParams?.get('clearCache')) {
                            preferCache = false;
                        }

                        if (
                            useCache &&
                            preferCache &&
                            cached?.timestamp > Date.now() - CACHE_TIMEOUT &&
                            localCacheTime === serverCacheTime
                        ) {
                            console.log('Fetching data from cache: ', cacheKey);
                            return cached.data;
                        } else {
                            console.log('Fetching data from server: ', cacheKey, url);
                            if (useCache) {
                                this.storage.setItem(this.cacheTimeKey, serverCacheTime);
                            }
                            return firstValueFrom(
                                this.httpClient.get(url, opts).pipe(
                                    timeout(30000), // TODO: Make this config
                                    tap((data) => {
                                        if (useCache) {
                                            console.log(`Caching ${data.length} items for ${cacheKey}`);
                                            this.storage.setItem(cacheKey, { timestamp: Date.now(), data });
                                        }
                                    }),
                                    catchError((err) => {
                                        if (err.status === 401) {
                                            console.error('User is no longer logged in');
                                            return;
                                        }
                                        console.groupCollapsed(
                                            'RestDataService: Error retrieving ' + cacheKey + ', falling back to cache.',
                                            err
                                        );
                                        console.error('RestDataService: Error retrieving ' + cacheKey + ', falling back to cache.', err);
                                        console.trace('RestDataService: Error stack trace');
                                        console.groupEnd();

                                        if (fallbackToCache && cached.data) {
                                            return of(cached.data);
                                        }
                                        return throwError(() => err);
                                    })
                                )
                            );
                        }
                    })
                );
            }),
            take(1)
        );
    }
}
