import { Store } from '@ngrx/store';
import { AppInjector, ArmyBuilderConfig, HttpClientRequestOptions, RestDataService, mapToPaginatedItems } from 'army-builder-shared';

import { catchError, of, switchMap, throwError } from 'rxjs';
import { ENTITY_LIBRARY_ACTIONS } from '../army-builder-entity-store';

export const getDataFromApi = <T>(
    gameId: string,
    dataType: string,
    opts?: HttpClientRequestOptions,
    callback?: ({ data }: { data: T[] }) => void
) => {
    const store = AppInjector.get(Store);
    const restDataService = AppInjector.get(RestDataService);
    const config = AppInjector.get(ArmyBuilderConfig);

    store.dispatch(ENTITY_LIBRARY_ACTIONS.UPDATE_LIBRARY_STATUS({ gameId, dataType, status: 'LOADING' }));
    restDataService
        .get(config.apiBaseUrl + '/library/' + gameId + '/' + dataType + '?getAll=1', {
            cacheKey: gameId + '_' + dataType,
            requiresLogin: true,
            ...(opts ?? {})
        })
        .pipe(
            catchError((err) => {
                store.dispatch(ENTITY_LIBRARY_ACTIONS.UPDATE_LIBRARY_STATUS({ gameId, dataType: dataType as string, status: 'ERROR' }));
                console.error(err);
                throw throwError(() => err);
            }),
            mapToPaginatedItems<T>(false),
            catchError(() => of(null))
        )
        .subscribe((data) => {
            if (!data) {
                return;
            }
            if (callback) {
                callback({ data });
            } else {
                store.dispatch(ENTITY_LIBRARY_ACTIONS.LOAD_ENTITY_DATA_AVAILABLE({ gameId, dataType: dataType as string, data }));
            }
        });
};

export const resetData = (arr: any[], data: any[]) => {
    // If we don't clear the array first we'll end up with duplicates (special rules spam)
    arr.length = 0;
    arr.push(...data);
};
