import { Injectable } from '@angular/core';
import { Purchases } from '@revenuecat/purchases-js';
import { ArmyBuilderConfig, HttpClientWithInFlightCache, SettingsService } from 'army-builder-shared';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SubscriptionService {
    public userHasAccess$ = this.settingsService.login$.pipe(
        map((l) => {
            return l.user?.roles?.includes('app_subscriber') || l.user?.roles?.includes('app_subscriber_annual');
        })
    );

    public newCustomer$ = this.settingsService.login$.pipe(
        map((l) => {
            return Object.keys(l.user?.entitlements ?? {}).length === 0;
        })
    );

    public userRoles$ = this.settingsService.login$.pipe(
        map((l) => {
            return l.user?.roles;
        })
    );

    public freeTrial$ = this.settingsService.login$.pipe(
        map((l) => {
            return l.user?.roles?.includes('app_free_trial');
        })
    );

    public expiry$ = this.settingsService.login$.pipe(
        map((l) => {
            const annualExpiry = new Date(l.user?.entitlements?.app_sub_annual?.expires_date).getTime() || 0;
            const monthlyExpiry = new Date(l.user?.entitlements?.app_sub?.expires_date).getTime() || 0;

            if (monthlyExpiry === 0 && annualExpiry === 0) {
                return null;
            }
            return new Date(Math.max(annualExpiry, monthlyExpiry));
        })
    );

    public hasExpired$ = this.expiry$.pipe(
        map((expiry) => {
            return expiry && expiry < new Date();
        })
    );

    constructor(
        private http: HttpClientWithInFlightCache,
        private settingsService: SettingsService,
        private config: ArmyBuilderConfig
    ) {}

    async checkSubscriptionStatus() {
        try {
            console.log('Checking subscription status');
            const customerInfo = await Purchases.getSharedInstance().getCustomerInfo();
            console.log({ customerInfo });
            // access latest customerInfo
        } catch (e) {
            // Handle errors fetching customer info
        }
    }
}
