import { Component } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { ArmyBuilderConfig, PurchasingService, SettingsService } from 'army-builder-shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CancelSubscriptionComponent } from './cancel-subscription.component';
import { SubscriptionService } from './subscription.service';
import { Router } from '@angular/router';
import { PaywallComponent } from './paywall.component';

@Component({
    selector: 'wl-manage-subscription',
    template: `
        <ng-container
            *ngLet="{
                hasExpired: hasExpired$ | async,
                user: user$ | async,
                expiry: expiry$ | async,
                expiryProgress: expiryProgress$ | async,
                expiryProgressColour: expiryProgressColour$ | async,
                newCustomer: newCustomer$ | async
            } as props"
        >
            @if (!props.newCustomer) {
                @if (props.hasExpired) {
                    <ion-card color="danger">
                        <ion-card-content> Your subscription expired {{ props.expiry | relativeDate }}. </ion-card-content>
                    </ion-card>
                } @else {
                    <ion-progress-bar [color]="props.expiryProgressColour" [value]="props.expiryProgress"></ion-progress-bar>
                    <p>Your subscription will expire {{ props.expiry | relativeDate }}.</p>
                }

                @if (props.user.roles.includes('app_subscriber_annual')) {
                    <p>Subscription tier: annual.</p>
                    <p>Check back soon to redeem your exclusive discount voucher on the Warlord web store.</p>
                } @else if (props.user.roles.includes('app_subscriber')) {
                    <p>Subscription tier: monthly.</p>
                    @if (web) {
                        <p>Upgrade your membership to receive exclusive discounts and early access to new content.</p>
                        <ion-button class="fullWidth" (click)="subscribe()">Upgrade to Annual Membership</ion-button>
                        <br /><br />
                    }
                }
            } @else {
                <ion-button class="fullWidth" (click)="subscribe()">Subscribe</ion-button>
            }

            <form action="{{ config.apiBaseUrl }}/purchase/createPortalSession" method="POST" *ngIf="web">
                <input type="hidden" name="accessToken" value="{{ props.user?.accessToken }}" />
                <ion-button class="fullWidth" type="submit">Manage Subscription</ion-button>
                <br /><br />
            </form>

            <p>
                If you have purchased a subscription and it is not reflected in the app, try the button below to manually refresh the
                subscription status. If that doesn't work, send an email to
                <a href="mailto:warlord@maloric.com">warlord&#64;maloric.com</a> and we'll do whatever we can to assist.
            </p>

            <ion-button class="fullWidth" (click)="refreshPurchases()">Refresh Subscription </ion-button>

            <!-- <ion-button (click)="checkSubscription()">Check Subscription</ion-button> -->
            <ng-container *ngIf="!stripeCheckout">
                <div *ngIf="subscription$ | async as product" class="buttons">
                    <ion-button color="danger" *ngIf="product.type === 'PAID_SUBSCRIPTION' && isOwned(product.key)" (click)="cancelSub()">
                        {{ 'GLOBAL.SUBSCRIPTION.CANCEL' | translate }}
                    </ion-button>
                </div>
            </ng-container>
        </ng-container>
    `,
    styles: [
        `
            .content {
                margin-bottom: 0;
            }
        `
    ]
})
export class ManageSubscriptionComponent {
    hasExpired$ = this.subscriptionService.hasExpired$;
    newCustomer$ = this.subscriptionService.newCustomer$;
    stripeCheckout = !(this.platform.is('android') || this.platform.is('ios'));
    subscription$ = this.purchasingService.products$.pipe(map((p) => p[0]));
    status$: Observable<string[]> = this.purchasingService.status$;
    user$ = this.settingsService.login$.pipe(map((l) => l.user));
    expiry$ = this.subscriptionService.expiry$;
    expiryProgress$ = this.expiry$.pipe(
        map((e) => {
            const daysRemaining = Math.floor((e.getTime() - Date.now()) / 1000 / 60 / 60 / 24);
            return daysRemaining / 28;
        })
    );

    expiryProgressColour$ = this.expiryProgress$.pipe(
        map((e) => {
            if (e > 0.5) {
                return 'success';
            }
            if (e > 0.25) {
                return 'warning';
            }
            return 'danger';
        })
    );

    web = !this.platform.is('cordova');

    constructor(
        public config: ArmyBuilderConfig,
        private subscriptionService: SubscriptionService,
        private platform: Platform,
        private purchasingService: PurchasingService,
        private modalController: ModalController,
        private settingsService: SettingsService,
        private router: Router
    ) {}

    purchase(productId: string) {
        this.purchasingService.purchase(productId);
    }

    subscribe() {
        this.modalController
            .create({
                component: PaywallComponent
            })
            .then((m) => m.present());
    }

    isOwned(productId: string) {
        return this.purchasingService.isOwned(productId);
    }

    async cancelSub() {
        const modal = await this.modalController.create({
            component: CancelSubscriptionComponent
        });

        modal.present();
    }

    refreshPurchases() {
        this.router.navigateByUrl('/subscription/handle-purchase');
    }
}
